.modal {
    overflow: auto;
}

// .mainpage-container {
//     display: flex;
//     flex-wrap: nowrap;
//     overflow: hidden;
// }

/*body.overflow-hidden main.page-wrapper {
    position: fixed;
    left: 220px;
    top: 145px;
    bottom: 0;
    height: auto;
}*/

// .page-wrapper-Container {
//     margin: 0px 0px 0px 0px;
//     height: 100%;
// }

// .page-wrapper-Container>div {
//     height: 100%;
// }

// .page-wrapper-Container.mini-navbar {
//     margin: 0px 0 0px 0px;
// }

.navbar.scrolling-navbar {
    transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.greyContainer {
    background-color: #f3f3f4;
}

//List Page Start Region
.listPageHeader,
.page-heading {
    /*left: 185px;
    right: 0;
    z-index: 400;
    top: 105px;*/
    /*background: #2196f3 !important;*/
    /*background: #1976D2 !important;*/
    /*background: #03A9F4 !important;*/
    color: inherit;
    /*padding: 10px 25px !important;*/
    padding: 0.625rem 0.75rem 0.625rem 0.75rem !important;
    z-index: 100;
}

// .employeePageHeading {
//     margin: 0.75rem;
//     margin-bottom: 0;
// }

#employeePageHeading.top-nav-collapse {
    position: fixed;
    top: 0;
    z-index: 100;
    right: 0;
    left: 220px;
}

#employeePageHeading.mini-navbar {
    left: 0px;
}

.page-heading.full-header {
    margin: 0 -24px;
}

.page-footer {
    /*left: 185px;
    right: 90px;*/
    /*z-index: 400;*/
    /*position: absolute;*/
    /*box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 0px 1px 0 rgba(0, 0, 0, 0.18) !important;*/
    box-shadow: none;
    background: transparent !important;
    /*padding: 10px 25px !important;*/
    padding: 10px 5px !important;
}

/*.page-heading h4, .page-heading h5 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}*/

//Button styles


.inviteBtn {
    background-color: #fff !important;
    border-color: #1a7bb9 !important;
    color: #1a7bb9 !important;
    border-radius: 3px !important;
    padding: 8px 24px !important;
    line-height: 1.5 !important;
    border: 1px solid #1a7bb9 !important;
    box-shadow: none;
    font-size: 13px !important;
    text-transform: none;
}

.inviteBtn:hover {
    background-color: #1a7bb9 !important;
    border-color: #1a7bb9 !important;
    color: #FFFFFF !important;
    border-radius: 3px !important;
    padding: 8px 24px !important;
    line-height: 1.5 !important;
    border: 1px solid transparent !important;
    box-shadow: none;
    font-size: 13px !important;
    text-transform: none;
}

.editBtn {
    background-color: #f9f9f9 !important;
    color: #2196f3 !important;
    border: none !important;
    box-shadow: none;
    font-weight: 500;
    font-size: 13px;
    text-transform: none;
}

.editBtn:hover {
    text-decoration: underline;
}

.textLink {
    color: #444 !important;
    font-size: 13px !important;
    padding: 5px 16px;
    margin-right: 10px;
    background: #f9f9f9;
    border-radius: 2px;
    font-weight: 400;
    outline: none;
    border: none !important;
}

.textLink:hover {
    transform: scale(1);
}

.rightSectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //background-color: #0177c1;
    background-color: #0283d4;
    color: #fff !important;
    font-weight: 400;
    padding: 0 10px;
    font-size: 14px;
    cursor: pointer;
    height: 38px;
    /*margin-bottom: 1.3rem !important;*/
}

.rightSectionHeader .link-button {
    color: #444 !important;
    font-size: 13px !important;
    padding: 5px 16px !important;
    background: #f9f9f9 !important;
    border-radius: 2px !important;
    font-weight: 400 !important;
    outline: none !important;
    border: none !important;
}

.statusValue {
    font-size: 16px;
    font-weight: 500;
    /*padding: 3px 10px;
   background-color: #94CA56;
    color: #fff;*/
    color: #333;
    border-radius: 2px;
}

.statusLable {
    font-size: 12px;
    font-weight: 400;
    color: #888888 !important;
    padding: 5px 0px;
}

.page-heading h3.ui.header {
    color: inherit !important;
}

.ui.header {
    border: none;
    margin: unset !important;
    padding: 0 0;
    font-weight: 600;
    line-height: 1.28571429em;
    text-transform: none;
    text-align: left;
    font-size: 18px;
}

h3.ui.header {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

h4.ui.header {
    font-size: 1.07142857rem;
}

.listPageHeader.mini-navbar,
.page-heading.mini-navbar,
.page-footer.mini-navbar {
    /*left: 45px;*/
    left: 0px;
}

.top-nav-collapse.listPageHeader,
.top-nav-collapse.page-heading {
    top: 0px;
    /*top:45px;*/
    z-index: 1032 !important;
}

/*.listPageHeader button, .page-heading button,
.listPageHeader a, .page-heading a {
    font-size: 11px;
    border-color: #0077C0 !important;
    background-color: #0077C0 !important;
    color: #fff !important;
    font-weight: 500;
}

.listPageHeader button:hover, .page-heading button:hover,
.listPageHeader a:hover, .page-heading a:hover {
    font-size: 10px;
    color:#fff !important;
    border-color: #0077C0 !important;
    background-color: #0077C0 !important;
}*/

.modal-header h5 {
    font-weight: 400 !important;
}

.listPageHeader .back-arrow,
.page-heading .back-arrow,
.listPageHeader .back-arrow:hover,
.page-heading .back-arrow:hover {
    border-color: transparent !important;
    background-color: transparent !important;
    font-size: 20px;
    color: #000 !important;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.full-header .back-arrow {
    margin-left: 24px;
}

.listPageHeader .back-arrow img,
.page-heading .back-arrow img {
    width: 18px;
    margin-top: 5px;
}

/*.loadingPanelGrid {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0px;
    width: unset !important;
    right: 0;
    background: #fff;
    opacity: 0.7;
    z-index: 200;
}
*/

.loadingPanel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0px;
    width: unset !important;
    right: 0;
    background: #fff;
    opacity: 0.8;
    z-index: 2000;
}



.loadingPanelGrid {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    // top: 150px;
    bottom: 0;
    // left: 220px;
    width: unset !important;
    right: 0px;
    background: #fff;
    opacity: 0.8;
    display: none;
}



.loadingPanelDetails {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px; //150px;
    bottom: 0;
    left: 0px; //220px;
    width: unset !important;
    right: 0px;
    background: #fff;
    opacity: 0.8;
}

.page-wrapper.mini-navbar .loadingPanelDetails {
    top: 0px;
    left: 60px;
}


/*.listPageContainer .e-spinner-pane.e-spin-template.e-spin-show:before {
    content: "";
    position: absolute;
    top: 81px;
    left: 0;
    bottom: 0;
    width: 100vw;
    background: rgba(255,255,255,0.8);
    z-index: 100;
}*/

.detailsRightSection .loadingPanel {
    position: absolute;
    left: 0;
    top: calc(50% - 40px)
}

.detailsRightSection .loadingPanelGrid {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 40px)
}

/*
.detailsRightSection .loadingPanelGrid {
    height: unset;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.detailsRightSection .loadingPanel {
    height: unset;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}*/

.e-dialog .loadingPanel {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.preparingLoadingPanel {
    margin-top: 14% !important;
    display: flex;
    text-align: center;
    justify-content: center;
}


.detailsRightSection .loadingPanelGrid {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
}

// .no-record-div {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     padding: 15px;
//     margin: 20px 10px;
// }

// .no-record-text {
//     font-size: 18px !important;
//     font-weight: 400 !important;
// }

// .no-record-text1 {
//     font-size: 14px !important;
//     font-weight: 400 !important;
//     padding-top: 12px;
// }

/*.detailsRightSection .no-record-div {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
}*/

.loadingPanelShort {
    margin-top: 5% !important;
    display: flex;
    text-align: center;
    justify-content: center;
}

// .no-record-div i {
//     font-size: 32px;
//     padding-bottom: 4px;
// }

// .no-record-div span {
//     font-size: 14px;
//     font-weight: 400;
// }

.loadingText {
    /*font-size: 14px;*/
    /*changed by j for login page*/
    font-size: 18px;
    color: #333;
    font-weight: 500;
    margin-top: 8px;
    text-align: center;
}

.form-control {
    /*border-color: #e5e6e7 !important;*/
    border-radius: 2px;
}

.datePickerControl {
    height: calc(1.5em + .5rem + 2px) !important;
    padding: .25rem .5rem !important;
    font-size: .875rem !important;
    line-height: 1.5 !important;
    /*border-color: #e5e6e7 !important;*/
    border: 1px solid #ced4da !important;
    border-radius: 2px !important;
    outline: none;
}

.react-datepicker__close-icon {
    top: -2px !important;
}

.react-datepicker__close-icon:after {
    background-color: transparent !important;
    color: #333 !important;
    font-size: 24px !important;
}

/*textarea {
    width: 100% !important;
    font-size: .875rem !important;
}*/

/*Select dropdown sytle */
.css-2b097c-container {
    width: 60%;
}

input::-webkit-input-placeholder {
    color: #A9A9A9 !important;
    font-weight: 400 !important;
    font-size: 13px;
}

.tableContainer {
    margin: 0px 0 0px 0;
}

.tableContainer .form-control,
.tableContainer .input-group-text,
.tableContainer .datePickerControl,
.tableContainer .css-2b097c-container {
    font-size: 13px !important;
}

.tableContainer .css-aytxt8-control,
.tableContainer .css-9tq4y2-control {
    min-height: 28px;
}

.css-aytxt8-control {
    box-shadow: none !important;
    border-color: #ced4da !important;
}

.css-9tq4y2-control {
    box-shadow: none !important;
}


.blue-gradient {
    /*background: linear-gradient(180deg, #b8d3ed, #42a5f5) !important;*/
    background: #42a5f5 !important;
}

.paginationDiv span {
    font-size: 13px;
}


//List page End Region


/*.page-wrapper {
    margin: 110px 0 0 220px;
    background-color: #eee;
    height: 100%;
    width: auto !important;
    right: 0;
    left: 0;
    overflow: auto;
}*/

/*Create Form Styles*/
.border-bottom {
    border-bottom: 1px solid #e7eaec !important;
}


.wrapper-header {
    height: 80px;
    left: 235px;
    right: 0;
    top: 110px;
    display: flex;
    z-index: 1000;
    color: inherit;
    font-weight: 100;
}

.wrapper-content {
    margin: 0px 0px 0px 0px;
    width: 100%;
}

.wrapper-content .row.border-bottom {
    border-bottom: 1px solid #212121 !important;
}

/*.wrapper-content .col div div {
    margin-left: 0px;
    margin-right: 0px;
}*/
.navbar.scrolling-navbar {
    transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}

/*.md-form input[type="text"]:not(.browser-default):focus:not([readonly]) {
    box-shadow: 0 0px 1px 1px #4285f4;
}*/
//Login Page Start Region
.copyRightsDiv {
    font-size: 12px;
    flex: 1;
    padding: 12px 48px 12px 48px;
    display: flex;
    align-items: flex-end;
}

.loginContainer {
    min-height: 90vh;
}

.loginContainer>div>h3 {
    font-weight: 400 !important;
}

.forgotUsername {
    font-size: 13px;
}

.logoImage {
    max-height: 100px;
    max-width: 100%;
}

.logoContainer {
    flex: 1;
}

.e-sidebar .brandLogoDiv .prodLogoImage_div {
    width: 65% !important;
    height: 65% !important;
}

.e-sidebar .brandLogoDiv .prodLogoImage_div .prodLogoImage {
    height: 80% !important;
}

.formContainer {
    flex: 3;
    padding: 8px 48px 12px 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*margin-top:10%;*/
}

.inner_container {
    align-items: flex-start !important;
    margin-top: 10% !important;
}

.carousel-item {
    display: inline-block;
    transition: transform .0s ease-in-out !important;
}

.sliderDiv {
    left: 0;
    right: 35%;
}

.carousel-dots {
    position: absolute;
    bottom: 0;
    z-index: 500;
    background-color: #fff;
    opacity: 0.5;
    margin: unset;
    padding: 0.5rem 0 !important;
}

.bgFirstSlide,
.bgSecondSlide,
.bgThirdSlide {
    background-image: url(https://s3.ap-southeast-2.amazonaws.com/beta.secure.skytrust.co/Content/img/1.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    position: initial;
}

.bgSecondSlide {
    background-image: url(https://s3.ap-southeast-2.amazonaws.com/beta.secure.skytrust.co/Content/img/2.png);
}

.bgThirdSlide {
    background-image: url(https://s3.ap-southeast-2.amazonaws.com/beta.secure.skytrust.co/Content/img/3.jpeg);
}

.login-leftPanel {
    // background-image: url(https://skytrust.co/Content/Images/Index/STsplashBG.jpg);
    background-repeat: repeat;
    background-size: cover;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.login-leftPanel .text-primary {
    color: #0071b2 !important;
    font-weight: 300;
    line-height: inherit;
    font-size: 20px;
}

.login-leftPanel .titleHeader {
    width: 60%;
    text-align: left;
}

.login-leftPanel .topHeader {
    position: absolute;
    top: 0;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: #2ecc71;
    text-align: center;
    padding: 6px 0px;
    height: 65px;
    display: flex;
    align-items: center;
}

.login-leftPanel .footer {
    position: absolute;
    bottom: 0;
    z-index: 1030;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #0071b2;
    text-align: center;
    height: 65px;
    display: flex;
    align-items: center;
}

.login-leftPanel .panel-content {
    overflow-y: auto;
    height: calc(100vh - 65px);
    margin-bottom: 65px;
}

.login-leftPanel .btn-trial {
    border-color: #fff !important;
    color: #fff !important;
}

.login-leftPanel .btn-trial:hover {
    background-color: #0d87e9 !important;
    border-color: transparent !important;
}

.login-leftPanel .customScrollBar::-webkit-scrollbar {
    width: 10px;
    height: 12px;
}

.login-leftPanel .customScrollBar::-webkit-scrollbar-button {
    background-color: transparent;
    background-repeat: no-repeat;
    cursor: pointer;
}

.login-leftPanel .customScrollBar::-webkit-scrollbar-corner {
    background-color: #fff;
}

.login-leftPanel .customScrollBar::-webkit-scrollbar-thumb {
    border-radius: 9px;
    border: solid 6px transparent;
    background-color: #c8c8c8;
}

.login-leftPanel .customScrollBar::-webkit-scrollbar-track {
    background-color: transparent;
}

//New style .loginContainer
.registerPage ul li {
    list-style-type: none;
}

.registerPage .attrEntry label {
    display: block;
    max-width: 100%;
    margin-bottom: 5px !important;
    font-weight: 500;
    text-align: left;
    color: #333;
}

.registerPage .normaltext,
.registerPage .smalltext,
.registerPage .tinytext {
    font-family: 'Segoe UI', Segoe, SegoeUI-Regular-final, Tahoma, Helvetica, Arial, sans-serif;
}

.registerPage .normaltext {
    font-size: .9em;
}

.registerPage,
.registerPage ul li input {
    width: 100%
}

//self_asserted style
.registerPage .helpLink {
    display: none;
}

.registerPage .showpasstoggler {
    margin-left: -30px;
    cursor: pointer;
}

.registerPage .TextBox label,
.registerPage .VerificationControl .attrEntry label {
    margin-left: 0px !important;
}

.registerPage .VerificationControl {
    margin-left: 5px;
    margin-right: -10px;
}

.registerPage .VerificationControl .buttons {
    display: flex;
    margin-top: 5px;
}

.registerPage .buttons {
    display: flex;
}

.registerPage .buttons #continue {
    margin-left: 5px;
}

.registerPage .verificationSuccessText,
.registerPage .verificationErrorText,
.registerPage .error.itemLevel.show {
    text-align: left !important;
    margin-bottom: 2px;
}

.registerPage .error {
    color: #a61e0c !important;
}

.registerPage .verificationErrorText {
    color: #d63301 !important;
}

.registerPage input[type=text]:focus,
.registerPage input[type=email]:focus,
.registerPage input[type=password]:focus {
    outline: 1px solid #4d90fe;
}

.registerPage input[type=text],
.registerPage input[type=email],
.registerPage input[type=password] {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    height: 32px;
    /*width: 300px;*/
    border: 1px solid #696969;
    z-index: 3;
    color: #000;
    padding: 0 0 0 3px;
    -moz-box-shadow: 0 0 0;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
    margin-right: 3px;
}

.registerPage .buttons button {
    -moz-user-select: none;
    cursor: pointer;
    margin-right: 4px;
    margin-left: 0;
    padding: 6px 12px;
    font-size: 100%;
}


.registerPage button:hover {
    background: #0f3e83;
    border: 1px solid #3079ed;
    -moz-box-shadow: 0 0 0;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
}

.registerPage button {
    width: auto;
    min-width: 50px;
    margin-top: 2px;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    background: #0078d4;
    border: 1px solid #fff;
    color: #fff;
    transition: background 1s ease 0s;
    font-size: 100%;
    padding: 0 2px;
}

.registerPage button,
.registerPage input,
.registerPage select,
.registerPage textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.registerPage button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer;
}

.registerPage button,
.registerPage select {
    text-transform: none;
}

.registerPage button {
    overflow: visible;
}

.registerPage button[aria-disabled=true],
.registerPage button[disabled] {
    background-color: #767676;
}

.registerPage .intro {
    text-align: left;
}

//Login Page End Region
//login new style start
.loginContainer h3 {
    text-align: center;
    color: #5E6C84;
    font-size: 20px;
    letter-spacing: -0.01em;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 500;
}

.loginContainer h5 {
    text-align: center;
    color: #777;
    font-size: 16px;
    letter-spacing: -0.01em;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 400;
}

.loginContainer button.e-primary {
    margin-right: 0 !important;
}

.left-section {
    min-width: 500px;
    max-width: 35%;
    flex: 0 0 35%;
}

.right-section {
    width: calc(100% - 500px);
}

//login new style end
.tab-content {
    padding: 35px 20px 20px 20px;
    width: 100%;
}

.listPageContainer h5 {
    text-align: center;
    color: #777;
    font-size: 16px;
    letter-spacing: -0.01em;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 400;
}

.page-content-outer {
    padding: 0.75rem;
}

.page-content-inner {
    // height: calc(100vh - 260px);
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    padding: 0 2px;
}

.page-content-inner.page-content-inner-sm {
    height: calc(100vh - 239px);
}

.page-wrapper.mini-navbar .page-content-inner {
    height: calc(100vh - 138px);
}

.page-content-outer.with-footer .page-content-inner {
    // height: calc(100vh - 291px);
    height: 100%;
    overflow: auto;
}

/*Composite control styles*/

.addressSection {
    padding: 15px 15px 15px 15px;
    background: #F7F8F9;
}

// .addressSection .col-sm-6:first-child {
//     padding-right: 5px;
// }

// .addressSection .col-sm-6:last-child {
//     padding-left: 5px;
// }

// .addressSection .col-sm-6.col-auto {
//     padding: unset;
//     margin-top: 4px !important;
// }

// .emailSection .form-group, .phoneSection .form-group {
//     margin-bottom: 5px !important;
// }

.createFormInputContainer label {
    font-size: 13px;
}

//Hamburger Menu
.hamburgerMenu {
    cursor: pointer;
}

.hamburgerMenu div {
    width: 24px;
    height: 2px;
    background-color: #fff;
    margin: 6px 0;
}

.navbar .breadcrumb .breadcrumb-item {
    font-size: 13px;
}

.navbar .breadcrumb .breadcrumb-item.active {
    font-size: 13px;
}

.navbar .breadcrumb .breadcrumb-item a {
    color: #fff;
    font-weight: 400;
    font-size: 13px;
}

.navbar .breadcrumb .breadcrumb-item.active {
    color: #fff;
    font-weight: 500;
    font-size: 13px;
}

.navbar .breadcrumb-item+.breadcrumb-item::before {
    color: #fff !important;
}

/*.navbar.scrolling-navbar.top-nav-collapse .brandLogoDiv {
    height:60px;
}*/
.navbar.scrolling-navbar.top-nav-collapse.page-heading .hamburgerMenu,
.navbar.scrolling-navbar.top-nav-collapse.listPageHeader .hamburgerMenu {
    /*display: block !important;*/
    display: none;
}

.wrapper-content .sectionHeader {
    background-color: #f3f3f4;
    /*background-color: #fff;*/
    /*background-color: transparent;*/
    color: #343a40;
    /*padding: 10px 20px 10px 20px;*/
    /*padding: 15px 20px 15px 20px;*/
    padding: 12px 20px 12px 5px;
    font-weight: 500;
    cursor: pointer;
    /*border-bottom: 1px solid #e7eaec;*/
}

.wrapper-content .accordionHeader {
    background-color: rgb(1, 119, 193);
    color: #fff !important;
    font-weight: bold;
    padding: 10px 5px;
    font-size: 13px;
    cursor: pointer;
    border: 1px solid #fff;
    border-top: unset;
    border-bottom: unset;
}

.wrapper-content .border-grey {
    border: 0.5px solid #ddd;
}

.accordionHeader .ui.header {
    color: #fff !important;
}

.collapse-content.fadein {
    height: auto;
    -webkit-transition: width 0.6s linear;
    transition: width 0.6s linear;
    -webkit-transform: translateZ(0) scale(1, 1);
    opacity: 1;
    display: block;
}

.collapse-content.fadeout {
    height: 0;
    -webkit-transition: width 0.6s linear;
    transition: width 0.6s linear;
    -webkit-transform: translateZ(0) scale(1, 1);
    display: none;
    opacity: 0;
}


.wrapper-content .collapseHeader {
    background-color: #f3f3f4;
    color: inherit;
    padding: 5px;
    font-weight: 500;
    cursor: pointer;
}

.collapseHeader i {
    font-size: 18px;
}

.detailSectionHeader {
    background-color: #fff;
    color: #343a40;
    padding: 10px 20px 10px 15px;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 1px solid #e7eaec;
    border-top: 1px solid #dee2e6;
}

.detailSectionHeader .fa {
    font-size: 16px;
}

.wrapper-content .form-label {
    font-size: 13px;
    font-weight: 500;
    margin-left: 0px !important;
}


.sectionHeader {
    background-color: #0283d4 !important;
}

.sectionHeader i,
.collapseHeader i {
    font-size: 18px;
    /*color: #879596;*/
    color: #16191f;
}

.sectionHeader i.small-icon {
    color: #fff;
}

.sky_header_bg {
    // background-color: #b8d3ed !important;
    // background-color: #c2e0f4 !important;
    // background: linear-gradient(#f0f8ff, #b8d3ed);
    // background-color: #e6f2ff !important;
    background-color: #0071b2cc !important;
    color: #fff;

    & .header-text,
    & .header-text-sm {
        color: #fff;
    }

    &_linear {
        background: linear-gradient(180deg, #e6f2ff, #b8d3ed) !important;
    }
}

.sky-top-header {
    position: relative;
    width: 100%;

    &::before {
        content: "";
        background-color: rgba(0, 113, 178, 0.8);
        height: 32px;
        position: absolute;
        left: 0rem;
        right: 0rem;
    }
}

.ui.header {
    border: none;
    padding: 0 0;
    font-weight: 500;
    line-height: 1.28571429em;
    text-transform: none;
    font-size: 1.28571429rem;
    margin: calc(2rem - .14285714em) 0 1rem;
    padding: 0 0;
    font-weight: 500;
    color: #1a7bb9;
}

h4.ui.header {
    font-size: 1rem;
}



.listPageHeader a>i,
.page-heading a>i {
    font-size: 16px !important;
}


.listPageHeader .back-arrow,
.page-heading .back-arrow,
.listPageHeader .back-arrow:hover,
.page-heading .back-arrow:hover {
    border-color: transparent !important;
    background-color: transparent !important;
    font-size: 20px;
    color: #fff;
}

.cursor {
    cursor: pointer !important;
}

//details page css styles
.detailsPageContainerCardRow {
    border-bottom: dashed 0.5px;
    border-color: #bdbdbd;
    display: flex;
    align-items: center;
    font-size: 13px;
    padding: 10px 0;
    padding-left: 10px !important;
}

.detailsPageContainerCardRow small {
    padding-right: 10px;
    font-size: 12px;
    font-weight: 500;
}

.detailsPageContainerCardRow .row div {
    display: flex;
    align-items: center;
}

.detailsPageContainerRow {
    position: relative;
}

.detailsValue {
    font-size: 13px;
    line-height: 2;
    font-weight: 400;
}

.detailsLabel {
    font-size: 12px;
    color: #888888 !important;
}

.hideLastRow {
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: #fff;
}

/*.detailsPageContainerRow .detailsPageContainerCardRow:nth-last-child(1) {
    border-bottom: unset;
}*/
/*.detailsPageContainerCardRow:nth-last-child(-n + 2) {
    border-bottom: unset;
}*/
.outsideClick.show {
    position: fixed;
    top: 0;
    right: 220px;
    z-index: 1036;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.6;
}

//Right Side Tags
.rightTagsModal {
    position: fixed;
    right: 5px;
    top: 145px;
    bottom: 0px;
    overflow: auto;
    width: 240px;
    font-size: 12px;
}

.top-nav-collapse.rightTagsModal {
    top: 50px;
}

.tagHeader {
    color: #375d96;
}

.tagsItem {
    margin-bottom: 5px;
    background-color: #f3f3f4;
}

.tagsItem a {
    color: #676A6C;
    font-weight: 500;
}


.tagsItem:hover a {
    background-color: #f3f3f4;
    color: #375d96;
    font-weight: 500;
}

.tagsItem.active a {
    color: #0079bf;
    font-weight: 500;
}

.tagsItem.active {
    background-color: #e4f0f6;
}

.tagsItem .fa.fa-check-circle {
    display: none;
}

.tagsItem.active .fa.fa-check-circle {
    display: block;
}

.rightTagsModal input {
    font-size: 13px;
}

//details page css styles
.detailsPanel {
    position: absolute;
    left: 230px;
    right: 255px;
    width: auto;
    margin-bottom: 30px;
}

.detailsPanel.mini-navbar {
    left: 10px;
}

.details-label {
    text-align: right;
}

.disabled {
    pointer-events: none;
    /* for "disabled" effect */
    opacity: 0.5;
}


.profileImg-container.raf-lg {
    min-width: 120px;
    width: 120px;
    min-height: 120px;
    height: 120px;
}

.profileImg-container-Overlay {
    min-width: 60px;
    width: 60px;
    min-height: 60px;
    height: 60px;
    background: #999;
    opacity: .7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.profileImg-container-Overlay.raf-lg {
    min-width: 120px;
    width: 120px;
    min-height: 120px;
    height: 120px;
}

.profilPhotoDiv .profileImg-container-Overlay {
    display: none;
}

.profilPhotoDiv:hover .profileImg-container {
    display: flex;
}

.profilPhotoDiv:hover .profileImg-container-Overlay {
    display: flex;
}

.profilPhotoDiv:hover .profileImg-container {
    display: none;
}


.profileImage {
    min-width: 70px;
    width: 70px;
    height: 70px;
    border-radius: 15px;
    background: #fff;
    border: 2px solid #fff;
}

.profileImg-container .link-button {
    font-weight: 400 !important;
}

.profileNameDiv {
    font-size: 18px;
    font-weight: 500;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    cursor: pointer;
    color: #1e1e1e;
}

.secondaryProfileName {
    font-size: 12px;
    color: #666;
    font-weight: 500;
}

.actionBtn {
    font-size: 12px !important;
    text-transform: capitalize;
}

.contentLeftMenu li {
    width: 100%;
    border: unset !important;
}

.contentLeftMenu .customTab .nav-link {
    width: 100%;
    text-align: left !important;
    border: unset !important;
    background: transparent !important;
    padding: 8px 20px !important;
}

.contentLeftMenu .customTab .nav-link:hover {
    font-weight: 400;
    color: #007fff !important;
    background: transparent !important;
}

.contentLeftMenu .customTab .nav-link.active {
    font-weight: 500;
    color: #007fff !important;
    background: transparent !important;
}

.customTab.contentTab .nav-link {
    background-color: transparent !important;
    color: #A7B1C2 !important;
    border: none;
    border-bottom: 4px solid transparent;
    border-radius: unset !important;
    padding: 10px 20px 10px 25px !important;
    margin: unset !important;
    font-size: 14px !important;
    font-weight: 500;
}

.customTab.contentTab .nav-link:hover {
    color: #555555 !important;
    background-color: transparent !important;
    border: unset;
    border-bottom: 4px solid transparent;
    border-radius: unset !important;
    font-size: 14px !important;
    font-weight: 500;
}

.customTab.contentTab .nav-link.active {
    background-color: transparent !important;
    color: #555555 !important;
    border: unset;
    border-bottom: 4px solid #1c84c6;
    border-radius: unset !important;
    font-size: 14px !important;
    font-weight: 500;
}

.listHeader {
    display: flex;
    align-items: center;
    align-self: center;
    cursor: pointer;
    justify-content: space-between;
}

.listHeader .filterIcon.btnDefaultIcon {
    border: none !important;
}

.listHeader .filterIcon.btnDefaultIcon:hover {
    border: none !important;
}

.viewFilters .dropdown .nav-link {
    color: #333;
}

.listActionBtns .dropdown-toggle {
    color: #333;
}

.viewFilters .dropdown .dropdown-menu .dropdown-item:active,
.topNavBar .dropdown .dropdown-menu .dropdown-item:active {
    color: #333 !important;
    background: #F4F4F4 !important;
    opacity: 1;
}

/*
.listPageHeader{
    padding-left:0px !important;
}

.listHeader {
    display: flex;
    align-items: center;
    align-self: center;
    cursor: pointer;
}

.listHeader .filterIcon {
    visibility: hidden;
}

.listHeader .viewFilterName:hover .filterIcon {
    visibility: visible;
}

.listHeader .viewFilterName:hover {
    background: #f1f3f4;
}

.listHeader .viewFilterName h5 {
    font-size: 20px;
    line-height: 30px;
}

.listHeader .viewFilterName .fa {
    line-height: 20px;
}

.viewFilterName {
    position: relative;
    display: flex;
    width: 100%;
    border-radius: 3px;
    align-items: center;
    padding:10px;
}

.viewMoreFilters {
    position: absolute;
    z-index: 100;
    background: #fff;
    left: 0;
    right: 0;
    top: 62px;
    padding: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 220px;
}

.ulviewMoreFilters {
    list-style-type: none;
}

.ulviewMoreFilters li {
    color: #333;
    padding: 10px 15px;
    cursor: pointer;
    opacity:.8;
    font-weight:400;
}

.ulviewMoreFilters li:hover{
    color: #333;
    background: #F4F4F4;
    opacity: 1;
}

.ulviewMoreFilters li.active {
    color: #333;
    font-weight: 500;
    background: #DEECF9;
    opacity: 1;
}
*/

/*Old UI styles*/
.leftLabel {
    color: #000;
    //background-color: #D2E9FF;
    // background-color: #f3f3f4;
    // background-color: #e6f1fc;
    background-color: #eff6fc;
    text-align: right;
    font-weight: 500;
    padding: 1.125rem 0.625rem 1rem 1.25rem;
    border-spacing: 5px;
    /*border: 1px solid #161440;*/
    border: 1px solid #fff;
    border-top: unset;
    display: flex;
    // align-items: flex-start;
    align-items: center;
    justify-content: flex-end;
    word-break: break-word;
    min-height: 50px;
}

.grey-label-bg .leftLabel {
    background-color: #f3f3f4;
}

.word-break-word {
    word-break: break-word;
}

.leftLabel label {
    margin: unset !important;
}

.rightData .form-value {
    color: #000;
    font-weight: 500;
    border: 1px solid #fff;
}

.details .leftLabel {
    border-bottom: 1px solid #f9f9f9;
    min-height: 40px;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    display: flex;
    align-items: center;


}

.details .leftLabel .form-label {
    line-height: 1.8rem;
}

.details .rightData {
    padding: 0.3125rem 0.625rem;
    border-bottom: 1px solid #eee;
    font-weight: 400;
    min-height: 40px;
    display: flex;
    align-items: center;
}

.details .rightData .detailsValue {
    font-size: 0.875rem;
    line-height: 1.8rem;
    font-weight: 500;
}

.custom .form-group {
    margin-bottom: 0px !important;
}

.rightData {
    padding: 10px;
    // padding-right: 3rem;
    /*border: 1px solid #161440;*/
    border: 1px solid #fff;
    border-top: unset;
    border-left: unset;
    border-right: unset;
}

// .inputFieldDeleteButton {
//     position: absolute;
//     right: 12px;
// }

.rightData .rightData {
    padding-top: 0;
    padding-bottom: 0;
}

.rightData .form-control {
    width: 60%;
}

/*.rightData .datePickerControl {
    width: 30%;
}*/

.datepicker-wrapper .react-datepicker-wrapper {
    width: 35%;
}

/*.rightData textarea {
    width: 90% !important;
}*/

.rightData .customBtn {
    display: flex;
    flex-direction: row;
}

.tab-heading {
    /* color: #2EAAEE; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /*background-color: #f7f7f7;*/
}

.tab-heading h6 {
    color: #1D73C0;
    font-weight: 500;
    margin-bottom: unset;
}

.customTabMenu {
    background: #f3f3f4;
    border: 1px solid #ddd;
    padding: 2px;
}

.customTab .nav-link {
    overflow: visible;
    /*color: #6e6d7a !important;*/
    padding: 5px 10px !important;
    /*background: rgba(13,12,34,0.05) !important;*/
    border-radius: 3px !important;
    margin: 1px .2em 0 0;
    text-align: center;
    border: 1px solid #c5c5c5;
    background: #f6f6f6 !important;
    font-weight: normal;
    color: #454545 !important;
    float: left;
    position: relative;
    top: 0;
    /*border-bottom-width: 0;*/
    font-size: 13px;
}

.customTab .nav-link:hover {
    /*color: #fff !important;
    background: #007fff !important;*/
    font-weight: 500;
}

.customTab .nav-link.active {
    /*color: #0d0c22 !important;*/
    color: #fff !important;
    /*background: rgba(13,12,34,0.05) !important;*/
    background: #007fff !important;
    border-radius: 3px !important;
    border: 1px solid #003eff;
}

.btnDefaultIcon {
    background-color: transparent !important;
}

/*.tab-pane table.table th {
    padding: .75rem;
    vertical-align: middle;
    border: 1px solid #dee2e6 !important;
    font-weight: 500;
    color: #1D73C0;
    font-size: 12px;
    white-space: nowrap;
    background-color: #fff;
}

.tab-pane table.table td {
    padding: .5rem .75rem;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6 !important;
    border-left: 1px solid #dee2e6 !important;
    border-right: 1px solid #dee2e6 !important;
    font-weight: 400;
    font-size: 12px;
    background-color: #fff;
}

.tab-pane table.table tr:nth-child(odd) td {
    background-color: #fff !important;
}

.tab-pane table.table tr:nth-child(even) td {
    background-color: #fff !important;
}

.tab-pane table td .btn {
    padding: 1px 8px;
    text-transform: capitalize;
    font-size: 10px;
    font-weight: 400;
    margin: unset;
    box-shadow: unset;
    background-color: #ffffff !important;
    border: solid 1px #D0D0D0 !important;
    color: #6C6C6C !important;
}

.tab-pane table tr td:last-child div {
    display: flex;
}

.tab-pane table tr td:last-child div .btn:first-child {
    margin-right: 4px;
}

.tab-pane table tr td:last-child .iconBtn {
    border: unset !important;
    height: 25px;
    width: 25px;
    font-size: 13px;
    display: none;
    align-items: center;
    justify-content: center;
    padding: unset;
}*/

.rightTabContent .e-card {
    min-height: 0;
}

.rightTabContent .table-responsive {
    padding: 2px;
}

.rightTabContent tbody {
    color: #676A6C !important;
}

.rightTabContent table .border-bottom {
    border-bottom: 1px solid #e0e0e0 !important;
}

.rightTabContent table thead th {
    background-color: #f3f3f4;
}

.rightTabContent .table thead th,
.table-bordered td {
    border-color: #fff;
}

.rightTabContent table.table th.blue-gradient,
table.table tbody td {
    border-left: 1px solid #fff;
}

.rightTabContent table.table td,
table.table th {
    vertical-align: middle;
    font-size: 13px !important;
}

.rightTabContent .table {
    cursor: pointer;
    margin-bottom: 0.25rem;
}

.rightTabContent .table tbody tr:hover {
    background-color: #fafafa !important;
}

.rightTabContent .table thead th,
.table-bordered td {
    border-color: #fff;
    position: relative;
}

.rightTabContent thead th {
    font-weight: 500;
}

.rightTabContent tbody td {
    font-weight: 400;
}

.rightTabContent .tableDataDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rightTabContent table.table td {
    padding-bottom: 12px;
    padding-top: 12px;
    vertical-align: middle;
    font-size: 13px;
    color: #17191c;
    font-weight: 400;
    border-bottom: 1px solid #f2f0f5 !important;
}

.rightTabContenttable.table th {
    padding-bottom: .7rem;
    padding-top: .7rem;
    vertical-align: middle;
    font-size: 13px;
}

/*.rightTabContent tr:nth-child(odd) td {
    background-color: #f1f3f4 !important;
}*/

.rightTabContent .tableHeader {
    background-color: #bbddee;
}

.rightTabContent .blue-gradient {
    background: linear-gradient(180deg, #e6f2ff, #b8d3ed) !important;
}

.rightTabContent table.table td span {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-bottom: 2px;
}

/*.rightTabContent {
    background-color: #f8f9fb;
}*/

.rightTabContent .customTabMenu {
    background-color: #fff !important;
    border: unset !important;
    padding: unset !important;
}

//Modal Section Class
.modal .card {
    margin-top: 25px !important;
}

.modal .card:first-child {
    margin-top: unset !important;
}

.modal-dialog.modal-right {
    margin: 0 !important;
}

.modal .modal-full-height.modal-right {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 450px;
    height: auto;
    min-height: 100%;
    margin: 0 !important;
}

.modal-full-height.modal-right .modal-header {
    position: fixed;
    top: 0;
    /*right: 0px;*/
    z-index: 10;
    background: #fff !important;
    padding: 10px 10px 10px 20px;
    font-size: 16px !important;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}

.modal-full-height.modal-right .modal-header h4,
.modal-full-height.modal-right .modal-header h5 {
    font-size: 18px !important;
    font-weight: 500;
}

.modal-full-height.modal-right .modal-header a>i {
    font-size: 16px !important;
}

.modal-full-height.modal-right .modal-body {
    /*position: fixed;*/
    width: 100%;
    overflow: auto;
    top: 0;
    bottom: 0;
    /*right:0;*/
    padding-top: 70px !important;
    padding-bottom: 70px !important;
}

.modal-body .collapse.show .card {
    //.search_inputbackground-color: #F3f3f3 !important;
    margin: unset;
}

.modal-full-height.modal-right .modal-footer {
    padding: 0px;
    border-top: none;
    position: fixed;
    bottom: 0;
    /*right: 0;*/
    width: 100%;
    //background-color: #fff;
    padding: 5px 20px 5px 20px;
    border-top: 1px solid #e7eaec;
    background: #f9f9f9;
}

.modal-dialog-centered .modal-body {
    background: #F7F7F7;
    padding: 10px;
    min-height: 400px;
}

.modal-dialog-centered .modal-footer input {
    width: 30%;
}

.modal-dialog-centered .modal-header {
    padding: 5px 1rem;
}


/*Status style*/
.status-active {
    font-size: 14px;
    font-weight: 500;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    padding: 5px 20px !important;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    cursor: pointer;
}

.status-inactive {
    font-size: 14px;
    font-weight: 500;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 5px 20px !important;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    cursor: pointer;
}

.inviteBtn {
    background-color: #fff !important;
    border-color: #1a7bb9 !important;
    color: #1a7bb9 !important;
    border-radius: 3px !important;
    padding: 8px 24px !important;
    line-height: 1.5 !important;
    border: 1px solid #1a7bb9 !important;
    box-shadow: none;
    font-size: 13px !important;
    text-transform: none;
}

.inviteBtn:hover {
    background-color: #1a7bb9 !important;
    border-color: #1a7bb9 !important;
    color: #FFFFFF !important;
    border-radius: 3px !important;
    padding: 8px 24px !important;
    line-height: 1.5 !important;
    border: 1px solid transparent !important;
    box-shadow: none;
    font-size: 13px !important;
    text-transform: none;
}

button:focus {
    outline: none;
}

//Input Text Floating Label style
.md-form {
    margin-top: 10px;
    margin-bottom: 5px;
}

.md-form>label {
    color: #9e9e9e;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0px !important;
    cursor: text;
    transition: color .2s ease-out, -webkit-transform .2s ease-out;
    transition: transform .2s ease-out, color .2s ease-out;
    transition: transform .2s ease-out, color .2s ease-out, -webkit-transform .2s ease-out;
    transform-origin: 0% 100%;
    text-align: initial;
    transform: translateY(14px);
}

.md-form input[type=text]:not(.browser-default) {
    line-height: 1.15;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    outline: none;
    height: 2.5rem;
    width: 100%;
    margin: 0 0 8px 0;
    padding: 0;
    box-shadow: none;
}

.md-form input[type=text]:not(.browser-default):focus:not([readonly]) {
    border-bottom: 1px solid #4D90FE;
}


.md-form>label.active {
    color: #4284f5 !important;
    font-weight: 400;
    font-size: 14px !important;
    transform: translateY(-8px) scale(0.8);
    transform-origin: 0 0;
}


//Sweet-alert styles
.sweet-alert h2 {
    font-size: 20px !important;
    font-weight: 500 !important;
}

.sweet-alert .btn {
    box-shadow: none !important;
    padding: 8px 24px !important;
    font-size: 13px !important;
}

.sweet-alert .btn-primary {
    box-shadow: none !important;
    background-color: #1a7bb9 !important;
    padding: 8px 24px !important;
    line-height: 1.5 !important;
    font-size: 13px !important;
}

//react-confirm-alert styles
.custom-ui {
    position: relative;
    min-width: 400px;
    background: #fff;
    border-radius: 4px;
    outline: none;
    margin: 50px 0;
    color: #333;
}

.custom-ui .heading {
    padding: 25px 20px 15px 25px;
    font-weight: 500;
    font-size: 20px;
}

.custom-ui .content {
    padding: 0 25px 10px;
    color: #878787;
    font-size: 14px;
    text-align: left;
}

.custom-ui .buttonAction {
    display: flex;
    justify-content: flex-start;
    padding: 10px 20px 20px;
}

.custom-ui .buttonAction>button {
    color: inherit;
    background-color: #fff;
    border: 1px solid #e7eaec;
    border-radius: 2px;
    padding: 6px 18px;
    margin: 0px 5px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 13px;
}

.custom-ui .buttonAction>button.cancel {
    color: inherit;
    background-color: #fff;
    border: 1px solid #e7eaec;
}

.custom-ui .buttonAction>button.cancel:hover {
    color: inherit;
    border: 1px solid #d2d2d2;
}

.custom-ui .buttonAction>button.confirm {
    color: #fff;
    background-color: #1a7bb9;
    border: none;
}

.react-confirm-alert-overlay {
    z-index: 5000 !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
}

// business unit Recent and Favourites navitem class

.rct-text {
    border: 0.5px solid transparent;
}

.favouritesTabPane .rct-node-leaf,
.recentTabPane .rct-node-leaf {
    padding: 2px;
}

.favouritesTabPane .rct-text,
.recentTabPane .rct-text {
    cursor: pointer;
    font-size: 14px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
}

.favouritesTabPane .rct-text:hover,
.recentTabPane .rct-text:hover {
    /*color: #fff;
    background-color: #0177c1;*/
    color: #333;
    background: #eee;
    border: .5px solid #cecece;
}

.favouritesTabPane .rct-text:hover .rct-title,
.recentTabPane .rct-text:hover .rct-title {
    /*color: #fff;*/
    color: #333;
}

.react-checkbox-tree ol>li {
    padding-right: 0px;
}

//spinner styles
.spinner-border {
    width: 36px !important;
    height: 36px !important;
}

.rightData .leftLabel {
    display: none;
}

/*.rightData .rightData.col-lg-7 {
    flex: 0 0 auto;
    max-width: unset;
    width: 100%;
}

.rightData .col-md-6 {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
    display: flex;
}*/

.rightData .col-md-6 .skyFormField.row {
    margin: 0;
}

.form-group {
    margin-bottom: 15px;
}

.form-label {
    position: relative;
    font-size: 0.8125rem !important;
    font-weight: 400;
    text-transform: uppercase;

    &.text-transform-none {
        text-transform: none;
    }
}

.hidden {
    display: none !important;
}

//grid style

.rafSFGrid .gridcheckboxclass .e-checkbox-wrapper .e-frame {
    width: 14px;
    height: 14px;
    border: 1px solid #a6a6a6;
    line-height: 13px;
    margin-bottom: 4px;
}

.rafSFGrid .gridcheckboxclass .e-checkbox-wrapper .e-icons.e-stop {
    font-size: 8px;
}

.rafSFGrid .gridcheckboxclass .e-checkbox-wrapper .e-icons.e-check {
    font-size: 10px;
}

.employeeFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;
    height: 32px;
    background-color: #fff;
    border-top: 1px solid rgb(231, 234, 236);
    display: flex;
    align-items: center;
    justify-content: center;
}

.employeeFooter .clientlogo {
    height: 24px;
    width: auto;
}

@media (max-width: 420px) {
    .SaveCancelSpan {
        display: none;
    }

    .page-heading .btn {
        padding: 0.5rem 0.75rem;
    }

    .SaveCancelIcon {
        display: block;
    }

    .rightData .customBtn {
        display: flex;
        flex-direction: column;
    }
}


@media (max-width: 576px) {
    .left-section {
        min-width: 100%;
    }

    .paginationDiv {
        flex-direction: column;
        justify-content: center;
        position: fixed;
        bottom: 0;
        height: 50px;
        left: 0;
        /*background-color: #212121 !important;
        color: #fff !important;*/
    }

    .emergencyContactSection {
        flex-direction: column;
    }

    .emergencyContactSection .col.ps-3 {
        padding-left: 0 !important;
    }

    .secondDatePicker {
        padding-left: unset !important;
    }

    .rightData .col-md-6 {
        flex-direction: column;
        max-width: 100%;
    }

    .centerDialog-sm {
        min-width: 90% !important;
    }

    .leftLabel {
        justify-content: flex-start !important;
        align-items: center;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        padding-left: 0.625rem;
    }
}

//downArrow style start

// .down-arrow, .up-arrow, .right-arrow, .left-arrow {
//     height: 7px;
//     width: 7px;
//     border-bottom: 1px solid #999;
//     border-right: 1px solid #999;
//     transform: rotate(45deg);
//     background-color: transparent;
//     transition: all 0.3s ease-in-out;
//     margin-bottom: 4px;
// }

// .up-arrow {
//     transform: rotate(135deg);
//     margin-bottom: 0px;
//     margin-top: 0px;
// }

// .right-arrow {
//     transform: rotate(-45deg);
//     margin-bottom: 0px;
//     margin-top: 0px;
// }

// .left-arrow {
//     transform: rotate(-225deg);
//     margin-bottom: 0px;
//     margin-top: 0px;
// }

.down-arrow,
.up-arrow {
    position: relative;
}

.down-arrow:before,
.up-arrow:before {
    content: "";
    height: 8px;
    width: 8px;
    border-bottom: 1px solid #999;
    border-right: 1px solid #999;
    position: absolute;
    transform: rotate(-45deg);
    right: 20px;
    margin-right: -5px;
    background-color: transparent;
    top: calc(50% - 4px);
    transition: all 0.3s ease-in-out;
}

//downArrow style end
//rafMenuTileComponent Style changes start
.list-group-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-group-item a:hover {
    text-decoration: underline;
    text-decoration-color: #666;
}

.list-group-item a {
    text-decoration: unset;
}

.list-group-item i {
    color: #666;
    font-size: 13px;
}

.list-group-item span {
    color: #666;
    font-size: 13px;
}

.list-group-item.active {
    color: #fff;
    /*background-color: #2196f3;*/
    background-color: #00adee;
    border-color: #2196f3 !important;
    font-size: 12px;
    text-transform: uppercase;
    border-top: unset;
}

.list-group-item.active span,
.list-group-item.active i {
    color: #fff;
    /*background-color: #2196f3;*/
    font-size: 12px;
}

//rafMenuTileComponent Style changes end


.dialog-height-lg {
    &.e-dialog {
        max-height: 90vh !important;
    }

    &.dlg-new-style .e-dialog .e-dlg-content-outer {
        max-height: 90vh !important;
    }
}

.dlg-new-style .e-dlg-headerContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;

    & h5 {
        color: #fff;
    }
}

.e-dlg-content-outer .e-dlg-body-content:not(.with-padding) {
    padding: 0;
}

.e-dropdown-btn.recordInfo-dropdown-btn {
    background: transparent !important;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    //width: 220px;
    color: #333;
    //overflow: hidden;
    min-width: auto;
    width: auto;
    min-height: auto !important;
    height: auto !important;
    line-height: normal;
}

button.custom-button-sm.e-dropdown-btn.recordInfo-dropdown-btn {
    min-height: auto !important;
    height: auto;
    min-width: unset !important;
    line-height: normal;
    font-weight: 500 !important;
    color: #666;
}

.page-wrapper {
    height: 100%;
    overflow: hidden !important;
}


.grid-toolbar-component .e-dropdown-btnDefault {
    font-size: 14px;
}

.e-dropdown-btnDefault ul {
    background: #fff !important;
}

// .e-dropdown-btnDefault:hover,
// .e-dropdown-btnDefault.e-active,
// .e-dropdown-btnDefault.e-btn:focus {
//     background: transparent !important;
//     border-color: transparent !important;
// }

.listPageHeading .e-dropdown-btnDefault.e-dropdown-popup {
    left: 1075px !important;
}

.page_heading_header_text {
    font: var(--header5-semi-bold);
    line-height: 1.5rem;
    color: var(--content-neutral-dark);
    white-space: normal;
    word-break: break-all;
}

@media (max-width: 576px) {
    .leftLabel.details-label {
        justify-content: flex-start;
        padding-top: 8px;
        padding-bottom: 7px;
    }
}

@media (max-width: 550px) {
    .modal .modal-full-height.modal-right {
        max-width: 90%;
    }

    .modal-full-height.modal-right .modal-header,
    .modal-full-height.modal-right .modal-footer {
        max-width: calc(90% - 0px);
    }
}

@media (min-width: 550px) {

    .modal-full-height.modal-right .modal-header,
    .modal-full-height.modal-right .modal-footer {
        max-width: calc(450px - 0px);
    }
}

@media (min-width: 600px) {

    /*.navbar.scrolling-navbar.top-nav-collapse {
        padding-top: 2px;
        padding-bottom: 2px;
    }*/
    .navbar.scrolling-navbar {
        padding-top: 5px;
        padding-bottom: 5px;
        transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
    }

    .navbar.scrolling-navbar.top-nav-collapse.page-heading,
    .navbar.scrolling-navbar.top-nav-collapse.listPageHeader {
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.10), 0 0px 1px 0 rgba(0, 0, 0, 0.18) !important;
        background-color: #F9F9FB !important;
    }

    .navbar.scrolling-navbar.topNavBar {
        padding-top: 0px;
        padding-bottom: 0px;
        transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
    }
}

@media only screen and (max-width: 767px) {
    .w-md-100 {
        width: 100%;
    }

    // .page-wrapper {
    //     width: calc(100vw);
    // }

    // .page-wrapper.mini-navbar {
    //     width: calc(100vw - 0px);
    // }

    // .page-wrapper-Container {
    //     margin: 0px 0 52px 0px;
    // }

    // .page-wrapper-Container.mini-navbar {
    //     margin: 0px 0 0 50px;
    // }

    .listPageHeader,
    .page-heading {
        left: 0px;
        /*top: 92px;
            height: 52px;*/
    }

    .listPageHeader .me-auto {
        margin-right: 0px !important;
    }


    .top-nav-collapse.listPageHeader,
    .top-nav-collapse.page-heading {
        top: 0px;
        z-index: 1032 !important;
    }

    .listPageHeader.mini-navbar,
    .page-heading.mini-navbar {
        /*left: 45px;*/
        left: 0px;
        /*top: 92px;*/
    }

    .page-footer,
    .page-footer.mini-navbar {
        left: 0px;
        right: 0px;
        padding: 15px 5px !important;
    }

    /*.listPageHeader .col-4:first-child {
        padding: 0px;
    }*/

    // .page-heading button,
    // .page-footer button,
    // .page-heading a,
    // .page-footer a {
    //     color: #000 !important;
    //     font-size: 13px;
    //     font-weight: 500;
    //     border-color: transparent !important;
    //     background-color: transparent !important;
    // }

    .page-heading button:disabled,
    .page-footer button:disabled {
        color: #a6a6a6 !important;
    }

    .page-heading button:hover,
    .page-footer button:hover,
    .page-heading a:hover,
    .page-footer a:hover,
    .page-heading button.btn-Default.e-active:hover {
        border-color: transparent !important;
        background-color: transparent !important;
    }

    .viewFilter .e-dropdown-btn {
        padding-left: 0;
        padding-right: 0;
    }

    .navbar.scrolling-navbar.top-nav-collapse.page-heading,
    .navbar.scrolling-navbar.top-nav-collapse.listPageHeader {
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.10), 0 0px 1px 0 rgba(0, 0, 0, 0.18) !important;
    }

    /*.rightData textarea {
        width: 100% !important;
    }*/

    .navbar .breadcrumb {
        padding-left: 0px;
    }

    .tableContainer {
        margin: 0px 0 0px 0;
    }

    .listPageTableContainer {
        left: 35px;
    }


    .wrapper-content .labelBackGroundColor.border-right {
        border-right: none !important;
        border-bottom: 1px solid #212121;
    }

    .wrapper-content {
        /*margin: 60px 0px 20px 0px;*/
        margin: 0px 0px 0px 0px;
    }

    .loadingPanelDetails {
        left: 0;

    }

    /*.modal-full-height.modal-right .modal-body {
        position: fixed;
        right: 0;
        
    }*/

    /*.modal .modal-full-height.modal-right {
        max-width: 90%;
    }

    .modal-full-height.modal-right .modal-header, .modal-full-height.modal-right .modal-footer {
        max-width: calc(350px - 0px);
    }*/
    .leftLabel.details-label {
        justify-content: flex-start;
        padding-top: 8px;
        padding-bottom: 7px;
    }

    //Transparent backdrop
    .transparentBackdrop.show {
        position: fixed;
        top: 0;
        left: 0px;
        z-index: 1030;
        width: 100vw;
        height: 100vh;
        background-color: #000;
        opacity: 0.5;
    }

    .outsideClick.show {
        position: fixed;
        top: 60px;
        right: 80%;
        z-index: 1036;
        width: 100vw;
        height: 100vh;
        background-color: #000;
        opacity: 0.5;
    }

    .searchWrapper {
        position: relative;
        margin-top: -3px;
        margin-right: 20px;
        cursor: pointer;
    }

    .search_input {
        background-color: transparent;
        width: 0;
        transition: width 0.3s linear;
        position: relative;
        border: none !important;
    }

    .search_input:focus {
        padding: 0 10px;
        width: 260px;
        transition: width 0.4s linear;
    }

    /*.listPageHeader h5 .listHeader {
        display: block !important;
        transition: width 0.4s linear;
    }

    .listPageHeader .listHeader.searchtoggle {
        display: none !important;
        transition: width 0.4s linear;
    }*/

    .detailsPanel {
        position: absolute;
        left: 10px;
        right: 10px;
    }

    .rightData .form-control,
    .rightData .datePickerControl,
    .datepicker-wrapper .react-datepicker-wrapper {
        width: 100%;
    }

    .viewFilters h5 {
        font-size: 18px !important;
    }

    .viewFilters .btn,
    .viewFilters .btn:hover {
        padding: 5px !important;
    }

    .listActionBtns {
        text-align: right;
    }

    .listActionBtns .btn {
        background-color: transparent !important;
        color: #000 !important;
        border: none !important;
        box-shadow: none !important;
        text-transform: unset;
        font-size: 14px !important;
        padding: 5px 0px !important;
        opacity: 0.9;
    }

    .listActionBtns .btn:hover {
        background-color: transparent !important;
        color: #000 !important;
        border: none !important;
        box-shadow: none !important;
        text-transform: unset;
        font-size: 14px !important;
        padding: 5px 0px !important;
        opacity: 0.9;
    }

    .listActionBtns .dropdown-toggle::after {
        margin-left: 5px;
        vertical-align: 2px;
    }

    .listActionBtns .dropdown-item i {
        opacity: 0.8;
    }

    .listActionBtns .btn.dropdown-toggle:hover,
    .listActionBtns .btn.dropdown-toggle:focus {
        background-color: transparent !important;
    }

    .listActionBtns .btn-primary:not([disabled]):not(.disabled):active,
    .btn-primary:not([disabled]):not(.disabled).active,
    .show>.btn-primary.dropdown-toggle {
        background: transparent !important;
    }

    .listActionBtns .dropdown-item {
        font-size: 13px;
        padding: .5rem 1.25rem !important;
    }

    .listActionBtns .dropdown .dropdown-menu .dropdown-item:active {
        background-color: transparent !important;
        color: #212529 !important;
    }

    .listActionBtns .dropdown-menu.show {
        transform: translate3d(-82px, 37px, 0px) !important;
    }

    .css-2b097c-container {
        width: 100%;
    }

    .custom-ui {
        width: 300px;
    }

    .detailsLeftSection.scrolled220 {
        position: fixed;
        top: 45px;
        //padding: 25px !important;
        z-index: 200;
        /*left: 24px;
        right: 24px;*/
        width: 100%;
    }

    /*.detailsLeftSection {
        padding-bottom: 20px;
        background-color: #f3f4f5;
    }*/

    .leftMenuData {
        //position: absolute;
        // height: 65vh;
        //min-height: 200px;
        //overflow: auto;
        width: 100%;
        //padding-right: 50px;
        z-index: 2;
        overflow: auto;
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
        // margin-top: -20px;
    }

    .detailsLeftSection.scrolled220 .leftMenuData {
        position: fixed;
        width: calc(100% - 50px);
    }

    .loadingPanelGrid {
        left: 0px;
        right: 0px;
    }

    .rightData {
        padding-right: 0.625rem;
    }

}



@media only screen and (max-width: 992px) {
    .detailsPageContainerCardRow:nth-last-child(-n + 2) {
        border-bottom: unset;
    }
}

@media only screen and (min-width: 1200px) {
    .emergencyContactDropDownItem {
        width: 120px;
    }
}

.rafmultiselectOuterDiv {
    width: 100%;
    position: relative;
}

.rafmultiselectOuterDiv .rafmultiselectTopInput {
    width: 100%;
    height: 100%;
    min-height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    border: solid 1px #c8c8c8;
}

.rafmultiselectOuterDiv .rafmultiselectBottomInput {
    width: 100%;
    height: 100%;
    min-height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8;
}